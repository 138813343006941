/* eslint-disable consistent-return */
import httpClient from '../utils/httpClient';
import GLOBAL from '../config/global';

const updatePreferredVendor = async ({ action, vendorTaxId }) => {
  const response = await httpClient.post(
    `${GLOBAL.API_URL}/updatePreferredVendor`,
    {
      action,
      vendorTaxId,
    },
  );
  return response.data.message;
};

/**
 * Sets or upserts a vendor on the server.
 * @param {Object} user - The user object to upsert.
 * @param {string} user.userEmail - The email of the user.
 * @param {string} user.userName - The name of the user.
 * @param {string} user.password - The VAT # of the user.
 * @returns {Promise<Object>} The response from the server.
 */
const setUser = async (user) => {
  const response = await httpClient.post(`${GLOBAL.API_URL}/setUser`, user);
  return response.data.message;
};

const UserAdapter = {
  updatePreferredVendor,
  setUser,
};

export default UserAdapter;
