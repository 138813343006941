import { useEffect, useState } from 'react';
import {
  Button,
  TextInput,
  Modal,
  Group,
  Card,
  Grid,
  Divider,
  Space,
  SimpleGrid,
  Switch,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';

import 'react-data-grid/lib/styles.css';

import DataTable from 'react-data-table-component';

import 'react-toastify/dist/ReactToastify.css';
import '@inovua/reactdatagrid-community/index.css';

function AdminDataView({ data, view, transactionViews }) {
  const [modalOpened, { open, close }] = useDisclosure(false);
  const [queueRows, setQueueRows] = useState([]);
  const [filteredData, setFilteredData] = useState(data);
  const [viewTableHeaders, setViewTableHeaders] = useState([]);
  const [activeRecordEdit, setActiveRecordEdit] = useState({});

  useEffect(() => {
    getViewDetails();
  }, []);

  const getViewDetails = () => {
    let tableheaderList = [];
    if (transactionViews.length !== 0) {
      const currentViewDetails = transactionViews.find(
        (item) => item.navBarDetails.modelLink === view,
      );
      for (
        let index = 0;
        index < currentViewDetails.tableHeaders.length;
        index++
      ) {
        const element = currentViewDetails.tableHeaders[index];
        let tableheaderObject = {
          name: <div>{element.name}</div>,
          selector: (row) => row[element.selector],
          sortable: element.sortable,
          editable: false,
          width: element.spacing,
        };
        tableheaderList.push(tableheaderObject);
      }
      if (currentViewDetails.viewable) {
        tableheaderList.push({
          cell: (row) => (
            <Group position="center">
              <Button
                onClick={() => viewItem(row.id, currentViewDetails)}
                id={row.id}>
                VIEW
              </Button>
            </Group>
          ),
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
        });
      }
      setViewTableHeaders(tableheaderList);
      let tableViewHeaders = currentViewDetails.tableHeaders;

      let rows = [];
      for (let index = 0; index < filteredData.length; index++) {
        const element = filteredData[index];
        let newRowItem = {};
        newRowItem['id'] = index;
        for (const key in element) {
          if (element.hasOwnProperty(key)) {
            for (let index = 0; index < tableViewHeaders.length; index++) {
              const tableHeader = tableViewHeaders[index];
              if (tableHeader.selector === key) {
                newRowItem[key] = element[key];
                break;
              }
            }
          }
        }
        rows.push(newRowItem);
      }
      setQueueRows(rows);
    }
  };

  function splitByCasing(inputString) {
    let splitString = inputString.split(/(?=[A-Z])/);
    let result = splitString.join(' ');
    result = result.toUpperCase();
    return result;
  }

  const viewItem = async (index, currentViewDetails) => {
    let modalFields = {};
    for (const key in filteredData[index]) {
      if (
        filteredData[index].hasOwnProperty(key) &&
        currentViewDetails.editableFields !== undefined &&
        currentViewDetails.editableFields !== null &&
        currentViewDetails.editableFields.includes(key)
      ) {
        modalFields[key] = filteredData[index][key];
      }
    }
    setActiveRecordEdit(modalFields);
    open();
  };

  let mainColumnHeaders = viewTableHeaders;

  return (
    <Card>
      <Grid>
        <Grid.Col span={6}>
          <Button
            variant="gradient"
            gradient={{ from: 'yellow', to: 'yellow', deg: 60 }}
            onClick={() => window.reload}>
            REFRESH
          </Button>
        </Grid.Col>
        <Grid.Col span={2}></Grid.Col>
        <Grid.Col span={2}></Grid.Col>
        <Grid.Col span={2}></Grid.Col>
      </Grid>
      <Divider my="sm" />
      <Space h="md" />
      <DataTable
        columns={mainColumnHeaders}
        data={queueRows}
        selectableRows={false}
        fixedHeader
        fixedHeaderScrollHeight="500px"
        pagination
      />
      <Modal size="xl" opened={modalOpened} onClose={close}>
        <SimpleGrid cols={2}>
          {Object.keys(activeRecordEdit).length > 0 ? (
            <>
              {Object.keys(activeRecordEdit).map((key) => (
                <>
                  {typeof activeRecordEdit[key] === 'string' ? (
                    <>
                      <div key={key}>
                        <TextInput
                          id={key + '_input'}
                          label={splitByCasing(key)}
                          defaultValue={activeRecordEdit[key] || ''}
                          error={!activeRecordEdit[key] ? 'Please add ' : ''}
                          // onChange={(e) => handleBotInputChange(key, e.target.value)}
                          withAsterisk
                        />
                      </div>
                    </>
                  ) : typeof activeRecordEdit[key] === 'boolean' ? (
                    <>
                      <Switch
                        size="xs"
                        label={splitByCasing(key)}
                        defaultChecked={activeRecordEdit[key]}
                      />
                    </>
                  ) : null}
                </>
              ))}
            </>
          ) : (
            <>No Editable Fields</>
          )}
        </SimpleGrid>
      </Modal>
    </Card>
  );
}

export default AdminDataView;
