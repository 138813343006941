import { forwardRef } from 'react';
import { Box, Text } from '@mantine/core';
import docStatusNameMap from '../constants/docStatusNameMap';

const SearchResultItem = forwardRef(
  ({ po_number, invoice_number, supplier, queue, ...others }, ref) => (
    <div ref={ref} {...others} key={others.value._id}>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '5px 10px',
        }}>
        {/* PO Number */}
        <Text
          size="sm"
          style={{
            minWidth: '60px',
            marginRight: '12px',
          }}>
          <Text color="dimmed">PO#</Text> {po_number || '-'}
        </Text>

        {/* Invoice Number */}
        <Text
          size="sm"
          style={{
            minWidth: '100px',
            marginRight: '12px',
          }}>
          <Text color="dimmed">INV#</Text> {invoice_number || '-'}
        </Text>

        {/* Supplier Name */}
        <Text
          size="sm"
          style={{
            flexGrow: 1,
          }}>
          <Text color="dimmed">Supplier</Text> {supplier || '-'}
        </Text>

        {/* Queue */}
        <Text
          size="sm"
          style={{
            marginLeft: '12px',
            justifySelf: 'end',
            textAlign: 'right',
            flexShrink: 0,
          }}>
          <Text color="dimmed">Queue</Text>{' '}
          {(docStatusNameMap[queue] || '-').replace(' Queue', '')}
        </Text>
      </Box>
    </div>
  ),
);

export default SearchResultItem;
