import { useEffect, useState } from 'react';
import { Button, TextInput, Stack, PasswordInput } from '@mantine/core';
import { isNotEmpty, useForm } from '@mantine/form';

import UserAdapter from '../adapters/userAdapter';

const UserForm = ({ onSuccess, onError, prefillData = {} }) => {
  const [submissionInProgress, setSubmissionInProgress] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const initialValues = {
    userId: '',
    userEmail: '',
    userName: '',
    password: '',
    ...prefillData,
  };

  useEffect(() => {
    // If `prefillData` object has prefill data (object keys)
    // then it is considered to be in "edit" mode.
    prefillData && setEditMode(Object.keys(prefillData).length !== 0);
  }, [prefillData]);

  const form = useForm({
    initialValues: initialValues,
    validate: {
      userName: isNotEmpty('Name is required.'),
      userEmail: (value) => {
        // Required validation
        if (!value) return 'Email is required.';

        // Borrowed from -> https://v6.mantine.dev/form/validators/#isemail
        const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
        if (!emailRegex.test(value)) return 'Invalid email address.';

        // Valid email
        return null;
      },
      password: !editMode && isNotEmpty('Password is required.'),
    },
    validateInputOnBlur: true,
  });

  const handleSubmit = async (values) => {
    if (submissionInProgress) return;

    setSubmissionInProgress(true);

    try {
      const response = await UserAdapter.setUser(values);
      onSuccess(response);
    } catch (error) {
      onError(error);
    } finally {
      setSubmissionInProgress(false);
    }
  };

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <Stack>
        <TextInput label="Name" {...form.getInputProps('userName')} required />
        <TextInput
          label="Email"
          {...form.getInputProps('userEmail')}
          onBlur={() => form.validateField('userEmail')}
          error={form.errors.userEmail}
          required
        />
        <PasswordInput
          label="Password"
          description={
            editMode
              ? 'Leave this field empty to avoid changing the password.'
              : ''
          }
          {...form.getInputProps('password')}
          required={!editMode}
        />
      </Stack>
      <Button
        type="submit"
        mt="md"
        loading={submissionInProgress}
        loaderProps={{ type: 'dots' }}>
        {submissionInProgress ? 'Submitting' : 'Submit'}
      </Button>
    </form>
  );
};

export default UserForm;
