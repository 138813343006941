import { Container, Group, Loader, Stack, Text, Title } from '@mantine/core';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import InvoiceApproverAdapter from '../adapters/invoiceApproversAdapter';

const ApproveInvoice = () => {
  const [searchParams] = useSearchParams();
  const [isValidating, setIsValidating] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const { invoice_id, token } = Object.fromEntries([...searchParams]);

    if (!invoice_id || !token) {
      setError('Invalid invoice Id or token.');
      setIsValidating(false);
    } else {
      processInvoiceApproval(invoice_id, token);
    }
  }, [searchParams]);

  const processInvoiceApproval = async (invoice_id, token) => {
    try {
      await InvoiceApproverAdapter.processInvoiceApproval({
        invoice_id,
        token,
      });
    } catch (error) {
      console.error('Error processing invoice approval:', error);
      setError('Signature verification failed. Invalid token.');
    } finally {
      setIsValidating(false);
    }
  };

  return (
    <Container
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}>
      <Stack align="center">
        {/* Platform Logo */}
        <img
          src="https://new.proteacoin.link/static/media/Juice_Primary_Logo.e958107328f60c9a5925.png"
          height={72}
          alt=""
        />

        {/* Validation in progress. */}
        {isValidating && (
          <>
            <Group position="apart">
              <Title order={2} color="blue">
                Processing...
              </Title>
              <Loader size={30} />
            </Group>
            <Text>
              Please wait, we're validating and processing your action.
            </Text>
          </>
        )}

        {/* Error state. */}
        {error && (
          <>
            <Title order={2} color="red">
              Something went wrong!
            </Title>
            <Text>{error}</Text>
          </>
        )}

        {!isValidating && !error && (
          <>
            <Title order={2} color="teal">
              Approved!
            </Title>
            <Text>You have successfully approved the invoice.</Text>
          </>
        )}
      </Stack>
    </Container>
  );
};

export default ApproveInvoice;
