/* eslint-disable consistent-return */
import httpClient from '../utils/httpClient';
import GLOBAL from '../config/global';

const doLogin = async (data) => {
  const body = {
    username: data.username,
    password: data.password,
  };
  const rtnObj = await httpClient.post(`${GLOBAL.API_URL}/login`, body);
  return rtnObj.data.message;
};

const LoginAdapter = {
  doLogin,
};

export default LoginAdapter;
