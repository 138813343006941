import packageJson from '../../package.json';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { Button, Container, Group, Loader, Text } from '@mantine/core';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

// import demoFile from "./demo.pdf";

/**
 * We faced an issue with PDF Rendering due to dependency version mismatch.
 * The following change prevents inadvertent version mismatch b/w pdfJS and pdfJS Worker.
 * More details & context:
 * - https://react-pdf-viewer.dev/docs/basic-usage/
 * - https://react-pdf-viewer.dev/examples/keep-the-worker-version-in-sync-with-pdfjs-dist-version/
 */
const pdfjsVersion = packageJson.dependencies['pdfjs-dist'];
const pdfjsWorkerLibUrl = `https://unpkg.com/pdfjs-dist@${pdfjsVersion}/build/pdf.worker.min.js`;

export default function PDFViewer({ fileURL, loading, error, switchView }) {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  if (loading || error) {
    return (
      <Container
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}>
        {/* Loading */}
        {loading && <Loader color="cyan" />}

        {/* Error */}
        {error && <Text color="red">Failed to fetch invoice</Text>}
      </Container>
    );
  }

  return (
    <Worker workerUrl={pdfjsWorkerLibUrl}>
      <div style={{ height: 'auto', maxHeight: '940px' }}>
        <Group mt="md" mb="xs">
          <Button type="button" onClick={() => switchView(fileURL)}>
            SWITCH VIEW
          </Button>
        </Group>
        <Viewer fileUrl={fileURL} plugins={[defaultLayoutPluginInstance]} />
      </div>
    </Worker>
  );
}
