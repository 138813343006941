import { forwardRef } from 'react';
import { Group, Text } from '@mantine/core';

const DocHistoryDropdownItem = forwardRef(
  ({ timestamp, label, user = '', ...others }, ref) => (
    <div ref={ref} {...others} key={others.value._id}>
      <Group noWrap>
        <div>
          <Text size="sm">{timestamp}</Text>
          <Text size="xs" color="dimmed">
            {user || 'Juice.AI'}
          </Text>
          <Text size="xs" color="dimmed">
            {label}
          </Text>
        </div>
      </Group>
    </div>
  ),
);

export default DocHistoryDropdownItem;
