import httpClient from '../utils/httpClient';
import GLOBAL from '../config/global';
import { useQuery, useQueryClient } from '@tanstack/react-query';

function useFetchUsers() {
  const queryClient = useQueryClient();

  const { data, error, isLoading } = useQuery({
    queryKey: ['users'],

    queryFn: () => fetchUsers(),

    staleTime: 5 * 60 * 1000,
  });

  const invalidateCache = () => {
    queryClient.invalidateQueries(['users']);
  };

  const refetch = () => {
    queryClient.refetchQueries(['users']);
  };

  return [data, error, isLoading, invalidateCache, refetch];
}

async function fetchUsers(id) {
  const response = await httpClient.get(`${GLOBAL.API_URL}/getUsers`);
  return response.data.message;
}

export default useFetchUsers;
