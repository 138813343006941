import httpClient from '../utils/httpClient';
import GLOBAL from '../config/global';

/**
 * Sets or upserts a vendor on the server.
 * @param {Object} vendor - The vendor object to upsert.
 * @param {string} vendor.email - The email of the vendor.
 * @param {string} vendor.name - The name of the vendor.
 * @param {string} vendor.taxId - The VAT # of the vendor.
 * @param {string} vendor.code - The ProteaCoin code of the vendor.
 * @returns {Promise<Object>} The response from the server.
 */
const setVendor = async (vendor) => {
  const response = await httpClient.post(`${GLOBAL.API_URL}/setVendor`, vendor);
  return response.data.message;
};

const Api = {
  setVendor,
};

export default Api;
