import { useCallback, useEffect, useState } from 'react';
import { Title, Card, Divider, Group, Button, Modal } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconPlus } from '@tabler/icons-react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import useFetchInvoiceApprovers from '../hooks/useFetchInvoiceApprovers';
import InvoiceApproverAdapter from '../adapters/invoiceApproversAdapter';
import InvoiceApproversList from '../components/InvoiceApproversList';
import InvoiceApproverForm from '../components/InvoiceApproverForm';

function InvoiceApprovers() {
  const [
    approvers = [],
    error,
    isLoading,
    invalidateApproversCache,
    refetchApprovers,
  ] = useFetchInvoiceApprovers();
  const [localApprovers, setLocalApprovers] = useState(approvers);
  const [modalOpened, { open: openModal, close: closeModal }] =
    useDisclosure(false);

  useEffect(() => {
    if (approvers?.length) {
      setLocalApprovers(approvers);
    }
  }, [approvers]);

  const handleFormSuccess = useCallback(() => {
    toast.success('Invoice Approver details saved successfully.');
    refetchApprovers();
    closeModal();
  }, [closeModal]);

  const handleFormError = useCallback(() => {
    toast.error('Something went wrong. Please try again.');
  }, []);

  const handleApproverEdit = useCallback(
    async ({ value, columnId, data }) => {
      const updatedApproverDetails = {
        ...data,
        [columnId]: value,
      };

      const updatedApprovers = localApprovers.map((approver) => {
        if (approver._id === data._id) {
          return { ...updatedApproverDetails };
        }
        return approver;
      });
      setLocalApprovers(updatedApprovers);

      try {
        await InvoiceApproverAdapter.setInvoiceApprover(updatedApproverDetails);
      } catch (err) {
        console.error(
          '🚩 ~ Failed to update approver details ~ err:',
          err.message,
        );
        toast.error('Failed to update approver details.');
      } finally {
        refetchApprovers();
      }
    },
    [localApprovers],
  );

  return (
    <>
      {/* Invoice Approvers Section */}
      <Card>
        <Group position="apart">
          <Title order={3}>Approver Management</Title>

          {/* Add or Update Invoice Approver */}
          <Button
            type="button"
            onClick={openModal}
            leftIcon={<IconPlus size={14}></IconPlus>}>
            ADD APPROVER
          </Button>
        </Group>
        <Divider my="sm" />

        {/* Invoice Approver's List */}
        <InvoiceApproversList
          approvers={localApprovers}
          error={error}
          loading={isLoading}
          onEdit={handleApproverEdit}
        />
      </Card>

      {/* Add / Edit Invoice Approver Modal */}
      <Modal
        size="md"
        opened={modalOpened}
        onClose={closeModal}
        title="New Approver"
        closeOnClickOutside={false}
        centered>
        <InvoiceApproverForm
          onSuccess={handleFormSuccess}
          onError={handleFormError}></InvoiceApproverForm>
      </Modal>
    </>
  );
}

export default InvoiceApprovers;
