import { useEffect, useState, useCallback } from 'react';
import { Loader, Center, Text, Button } from '@mantine/core';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import filter from '@inovua/reactdatagrid-community/filter';
import '@inovua/reactdatagrid-community/index.css';

// https://reactdatagrid.io/docs/filtering#filter-types-&-operators
const defaultFilterValue = [
  { name: 'userName', operator: 'contains', type: 'string', value: '' },
  { name: 'userEmail', operator: 'contains', type: 'string', value: '' },
  { name: 'id', operator: 'contains', type: 'string', value: '' },
];

const useFilteredUsers = (users, filterValue) => {
  const applyFilter = useCallback(() => {
    if (filterValue.every((f) => f.value === '')) {
      // Restore original data if all filters are empty
      return users;
    } else {
      // Apply filters to the vendors
      return filter(users, filterValue);
    }
  }, [users, filterValue]);

  return applyFilter;
};

function UserList({ users, error, loading, onEdit }) {
  const [filteredUsers, setFilteredUsers] = useState(users);
  const [filterValue, setFilterValue] = useState(defaultFilterValue);

  // Centralized filter logic
  const applyFilter = useFilteredUsers(users, filterValue);

  const gridColumns = [
    { name: 'userName', header: 'Name', defaultFlex: 2, minWidth: 100 },
    { name: 'userEmail', header: 'Email', defaultFlex: 2, minWidth: 100 },
    // { name: 'id', header: 'Juice ID', defaultFlex: 1, minWidth: 100 },
    {
      name: 'action',
      header: 'Action',
      textAlign: 'center',
      render: ({ data }) => <Button onClick={() => onEdit(data)}>EDIT</Button>,
    },
  ];

  useEffect(() => {
    setFilteredUsers(applyFilter());
  }, [applyFilter]);

  const onFilterValueChange = useCallback(
    (newFilterValue) => {
      setFilterValue(newFilterValue);
      setFilteredUsers(applyFilter());
    },
    [applyFilter],
  );

  if (loading) {
    return (
      <Center style={{ height: '100vh' }}>
        <Loader color="cyan" />
      </Center>
    );
  }

  if (error) {
    return (
      <Center style={{ height: '100vh' }}>
        <Text color="red">Failed to fetch users</Text>
      </Center>
    );
  }

  return (
    <ReactDataGrid
      idProperty="_id"
      style={{ minHeight: 'calc(100dvh - 180px)' }}
      onFilterValueChange={onFilterValueChange}
      filterValue={filterValue}
      columns={gridColumns}
      dataSource={filteredUsers}
      pagination={true}
      defaultLimit={15}
      pageSizes={[5, 10, 15, 20]}
    />
  );
}

export default UserList;
