import axios from 'axios';
import GLOBAL from '../config/global';

const httpClient = axios.create();

httpClient.defaults.withCredentials = true;

httpClient.interceptors.request.use(async (config) => {
  const accessToken =
    config.headers.Authorization ||
    `Bearer ${localStorage.getItem('accessToken')}`;

  // Attach access token if it exists
  if (accessToken) {
    config.headers.Authorization = accessToken;
  }

  return config;
});

// Intercept and handle 401 errors...
httpClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    console.error('✋ ~ file: httpClient.js:24 ~ error:', error.message);

    if (error.response) {
      const status = error.response.status;

      if (status === 401) {
        // Access token expired, try to refresh
        try {
          await refreshAccessToken();

          // Retry the failed request with the new access token
          error.config.headers.Authorization = `Bearer ${localStorage.getItem('accessToken')}`;
          return httpClient.request(error.config);
        } catch (refreshError) {
          return Promise.reject(refreshError);
        }
      } else if (status === 403) {
        // Refresh token expired, redirect to login
        console.error('Refresh token expired. Redirecting to login.');
        window.location.href = './login'; // Redirect to the login page
      }
    }
    return Promise.reject(error);
  },
);

/**
 * Renew access token using refreshToken (cookie).
 */
async function refreshAccessToken() {
  try {
    const { data } = await httpClient.post(`${GLOBAL.API_URL}/refreshToken`);
    localStorage.setItem('accessToken', data.accessToken);
  } catch (error) {
    console.error('Error refreshing access token:', error.message);
    throw error;
  }
}

export default httpClient;
