import { useState } from 'react';
import { Button, TextInput, Stack } from '@mantine/core';
import { isEmail, isNotEmpty, useForm } from '@mantine/form';

import VendorAdapter from '../adapters/vendorAdapter';

const VendorForm = ({ onSuccess, onError }) => {
  const [submissionInProgress, setSubmissionInProgress] = useState(false);

  const form = useForm({
    initialValues: {
      code: '',
      name: '',
      taxId: '',
      email: '',
    },
    validate: {
      code: isNotEmpty('Vendor code is required.'),
      name: isNotEmpty('Vendor name is required.'),
      email: isEmail('Invalid email address.'),
      taxId: isNotEmpty('Vendor VAT number is required.'),
    },
    validateInputOnBlur: true,
  });

  const handleSubmit = async (values) => {
    if (submissionInProgress) return;

    setSubmissionInProgress(true);

    try {
      const response = await VendorAdapter.setVendor(values);
      onSuccess(response);
    } catch (error) {
      onError(error);
    } finally {
      setSubmissionInProgress(false);
    }
  };

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <Stack>
        <TextInput
          label="Vendor Code"
          {...form.getInputProps('code')}
          required
        />
        <TextInput
          label="Vendor Name"
          {...form.getInputProps('name')}
          required
        />
        <TextInput label="VAT #" {...form.getInputProps('taxId')} required />
        <TextInput
          label="Vendor Email"
          {...form.getInputProps('email')}
          onBlur={() => form.validateField('email')}
          error={form.errors.email}
        />
      </Stack>
      <Button
        type="submit"
        mt="md"
        loading={submissionInProgress}
        loaderProps={{ type: 'dots' }}>
        {submissionInProgress ? 'Submitting' : 'Submit'}
      </Button>
    </form>
  );
};

export default VendorForm;
