import httpClient from '../utils/httpClient';
import GLOBAL from '../config/global';
import { useQuery, useQueryClient } from '@tanstack/react-query';

function useFetchDocument(id) {
  const queryClient = useQueryClient();

  const { data, error, isLoading } = useQuery({
    queryKey: ['documentDetails', id],

    queryFn: () => fetchDocument(id),

    // Only run the query if id is truthy
    enabled: !!id,

    staleTime: 60 * 1000,
  });

  const document = !id ? {} : data;

  const invalidateDocumentCache = () => {
    queryClient.invalidateQueries(['documentDetails', id]);
  };

  const refetchDocument = () => {
    queryClient.refetchQueries(['documentDetails', id]);
  };

  return [document, error, isLoading, invalidateDocumentCache, refetchDocument];
}

async function fetchDocument(id) {
  const response = await httpClient.get(`${GLOBAL.API_URL}/getDocument/${id}`);
  return response.data.message;
}

export default useFetchDocument;
