import { Select } from '@mantine/core';

import docStatusNameMap from '../constants/docStatusNameMap';
import DocHistoryDropdownItem from './DocHistoryDropdownItem';
import useDocHistory from '../hooks/useDocHistory';

const DocHistoryDropdown = ({ sourceId, onSelect }) => {
  const docHistory = useDocHistory(sourceId);

  const options = docHistory
    .filter((logItem) => logItem.key === 'document')
    .map((logItem) => ({
      value: logItem._id,
      doc: logItem.value,
      timestamp: new Date(logItem.createdAt).toLocaleString(),
      label: docStatusNameMap[logItem.value.state] || '',
      user: logItem.value?.lastUpdatedBy?.userName || '',
    }));

  const handleChange = (value) => {
    const selectedOption = options.find((item) => item.value === value);
    onSelect(selectedOption?.doc || null);
  };

  return (
    // https://v6.mantine.dev/core/select/#custom-item-component
    <Select
      label="Document History"
      data={options}
      onChange={handleChange}
      itemComponent={DocHistoryDropdownItem}
      placeholder="Select Doc Version"
      searchable
      nothingFound="No matching option..."
      clearable
      filter={(value, item) =>
        item.label.toLowerCase().includes(value.toLowerCase().trim()) ||
        item.user.toLowerCase().includes(value.toLowerCase().trim()) ||
        item.timestamp.toLowerCase().includes(value.toLowerCase().trim())
      }
    />
  );
};

export default DocHistoryDropdown;
