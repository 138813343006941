import { Group, Button } from '@mantine/core';

import docStatusMap from '../constants/docStatusMap';

export function ViewButtonComponent({ view, processCasses, failCasses }) {
  let buttons = <></>;
  if (view === docStatusMap.batchQ) {
    buttons = (
      <Group justify="space-between">
        <Button
          variant="gradient"
          gradient={{ from: 'teal', to: 'teal', deg: 60 }}
          onClick={processCasses}>
          PROCESS TRANSACTION
        </Button>
      </Group>
    );
  }
  if (view === '2626a85b-7abd-456f-aa20-0b0351711a50') {
    buttons = (
      <>
        <Button
          variant="gradient"
          gradient={{ from: 'teal', to: 'teal', deg: 60 }}
          onClick={processCasses}>
          MOVE TO EXPORT
        </Button>
        <Button
          variant="gradient"
          gradient={{ from: 'orange', to: 'orange', deg: 60 }}
          onClick={failCasses}>
          PUSH BACK
        </Button>
      </>
    );
  }
  if (view === 'b2aaa12c-9014-4d88-a08e-c309a1e8b7b9') {
    buttons = (
      <>
        {/* <a href={waterExportFile} target='_blank'>
          <Button variant="gradient" gradient={{ from: 'blue', to: 'blue', deg: 60 }}>EXPORT TO CSV WATER</Button>
        </a>
        <a href={elecExportFile} target='_blank'>
          <Button variant="gradient" gradient={{ from: 'green', to: 'green', deg: 60 }} >EXPORT TO CSV ELEC</Button>
        </a> */}
        <Button
          variant="gradient"
          gradient={{ from: 'teal', to: 'teal', deg: 60 }}
          onClick={processCasses}>
          EXPORT TO SFTP
        </Button>
      </>
    );
  }
  if (view === docStatusMap.completeQ) {
    <>
      <Button
        variant="gradient"
        gradient={{ from: 'teal', to: 'blue', deg: 60 }}
        onClick={processCasses}>
        GENERATE CSV
      </Button>
    </>;
  }
  return <>{buttons}</>;
}
