import { useEffect, useState } from 'react';
import { Box, NavLink } from '@mantine/core';

function NavBarItems({ setView, navbarData, activeTabIndex = 0 }) {
  const [active, setActive] = useState(activeTabIndex);

  useEffect(() => {
    setActive(activeTabIndex);
  }, [activeTabIndex]);

  const handleClick = (index) => {
    setActive(index);
    setView(index);
  };

  const items = navbarData.map((item, index) => (
    <NavLink
      key={item.label}
      active={index === active}
      label={item.label.toUpperCase()}
      description={item.description}
      icon={<item.icon size="1rem" stroke={1.5} />}
      onClick={() => handleClick(index)}
      color="cyan"
      variant="filled"
    />
  ));

  return <Box>{items}</Box>;
}

export default NavBarItems;
