import {
  AppShell,
  Navbar,
  useMantineTheme,
  Image,
  NavLink,
  Header,
  Container,
  Group,
} from '@mantine/core';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { IconManualGearbox } from '@tabler/icons-react';

import { UserMenu } from '../components/_userMenu';
import logo from '../assets/images/logo/CMYK/logo/png/Juice_Primary_Logo.png';

function AdminLayout2({ setTheme }) {
  const theme = useMantineTheme();
  const location = useLocation();
  const navigate = useNavigate();

  const menuItems = [
    {
      label: 'User Management',
      path: '/admin/users',
      icon: IconManualGearbox,
    },
    {
      label: 'Approver Management',
      path: '/admin/approvers',
      icon: IconManualGearbox,
    },
    {
      label: 'Vendor Management',
      path: '/admin/vendors',
      icon: IconManualGearbox,
    },
  ];

  return (
    <AppShell
      styles={{
        main: {
          background:
            theme.colorScheme === 'dark'
              ? theme.colors.dark[8]
              : theme.colors.gray[1],
        },
      }}
      navbarOffsetBreakpoint="sm"
      asideOffsetBreakpoint="sm"
      header={
        <Header height={64} py="xs">
          <Container fluid>
            <Group position="start">
              {/* Logo */}
              <Image
                width={150}
                maw={150}
                mt="-4px"
                radius="md"
                src={logo}
                alt="Logo"
                onClick={() => navigate('/')}
              />
            </Group>
          </Container>
        </Header>
      }
      navbar={
        <Navbar
          hiddenBreakpoint="sm"
          hidden={true}
          width={{ sm: 150, md: 180, lg: 240 }}>
          <Navbar.Section grow>
            {/* Admin Navigation Menu */}
            {menuItems.map((item) => (
              <NavLink
                key={item.path}
                label={item.label.toUpperCase()}
                component={Link}
                to={item.path}
                icon={<item.icon size="1rem" stroke={1.5} />}
                active={location.pathname === item.path}
                color="cyan"
                variant="filled"
              />
            ))}
          </Navbar.Section>
          <Navbar.Section>
            <UserMenu setTheme={setTheme} />
          </Navbar.Section>
        </Navbar>
      }>
      <Outlet />
    </AppShell>
  );
}

export default AdminLayout2;
