import httpClient from '../utils/httpClient';
import GLOBAL from '../config/global';
import { useQuery } from '@tanstack/react-query';

function usePurchaseOrderDetails(poNumber, accessToken = '') {
  const { data, error, isLoading } = useQuery({
    queryKey: ['purchaseOrderDetails', poNumber],

    queryFn: () => fetchPurchaseOrderDetails(poNumber, accessToken),

    // Only run the query if poNumber is truthy
    enabled: !!poNumber,

    staleTime: 60 * 1000,
  });

  return [data, error, isLoading];
}

async function fetchPurchaseOrderDetails(poNumber, accessToken) {
  const reqConfig = {};

  if (accessToken) {
    reqConfig.headers = {
      Authorization: `Bearer ${accessToken}`,
    };
  }

  const response = await httpClient.get(
    `${GLOBAL.API_URL}/getPurchaseOrderDetails/${poNumber}`,
    reqConfig,
  );
  return response.data.message;
}

export default usePurchaseOrderDetails;
