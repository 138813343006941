import { useEffect, useState, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Alert,
  Button,
  Card,
  Container,
  Divider,
  Grid,
  Group,
  Loader,
  Select,
  SimpleGrid,
  Stack,
  Tabs,
  Text,
  TextInput,
  Title,
  Tooltip,
} from '@mantine/core';
import {
  IconArrowLeft,
  IconInfoCircle,
  IconMailFast,
} from '@tabler/icons-react';
import { modals } from '@mantine/modals';
import { useForm } from '@mantine/form';
import { toast } from 'react-toastify';
import ReactDataGrid from '@inovua/reactdatagrid-community';

// import 'react-data-grid/lib/styles.css';
import 'react-toastify/dist/ReactToastify.css';
import '@inovua/reactdatagrid-community/index.css';

import docStatusNameMap from '../constants/docStatusNameMap.js';
import docStatusMap from '../constants/docStatusMap.js';
import TransactionAdapter from '../adapters/transactionAdapter.js';
import InvoiceApproverAdapter from '../adapters/invoiceApproversAdapter.js';
import usePurchaseOrderDetails from '../hooks/usePurchaseOrderDetails.jsx';
import useSignedUrl from '../hooks/useSignedUrl.jsx';
import useFetchDocument from '../hooks/useFetchDocument.jsx';
import formatCurrency from '../utils/formatCurrency.js';
import PDFViewer from '../components/PDFViewer';
import DocHistoryDropdown from '../components/DocHistoryDropdown';
import SelectApproverItem from '../components/SelectApproverItem';
import PurchaseOrderLines from '../components/PurchaseOrderLines';

const hideDocHistoryFrom = [
  docStatusMap.zeroQ,
  docStatusMap.completeQ,
  docStatusMap.squashQ,
];

// List of views where Squash button is expected to be visible.
const squashViews = [
  docStatusMap.manualQ,
  docStatusMap.queryQ,
  docStatusMap.poNoMatchQ,
  docStatusMap.noGrvQ,
  docStatusMap.serviceInvoiceQ,
  docStatusMap.rejectedQ,
];

// List of views where Push Back button is expected to be visible.
const pushBackViews = [docStatusMap.batchQ];

// List of views where detailed invoice view will allow
// the users to update the form values...
const editableViews = [
  docStatusMap.manualQ,
  docStatusMap.poNoMatchQ,
  docStatusMap.queryQ,
  docStatusMap.serviceInvoiceQ,
  docStatusMap.noGrvQ,
  docStatusMap.rejectedQ,
];

const expenseColumn = [
  {
    name: 'Code',
    header: 'Code',
    defaultFlex: 2,
    defaultVisible: false,
    sortable: false,
  },
  {
    name: 'Description',
    header: 'Description',
    defaultFlex: 4,
    sortable: false,
  },
  {
    name: 'Quantity',
    header: 'Quantity',
    render: ({ value }) => Number(value).toFixed(2),
    defaultFlex: 1,
    headerAlign: 'center',
    textAlign: 'end',
    sortable: false,
  },
  {
    name: 'UnitPrice',
    header: 'Unit Price',
    render: ({ value }) => formatCurrency(value),
    defaultFlex: 1,
    textAlign: 'end',
    headerAlign: 'center',
    sortable: false,
  },
  {
    name: 'Discount',
    header: 'Discount',
    defaultFlex: 1,
    textAlign: 'center',
    sortable: false,
  },
  {
    name: 'LineTotal',
    header: 'Line Total',
    render: ({ value }) => formatCurrency(value),
    defaultFlex: 1,
    textAlign: 'end',
    headerAlign: 'center',
    sortable: false,
  },
];

function DocumentDetails() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [document, error, isLoading, invalidateDocumentCache, refetchDocument] =
    useFetchDocument(id);
  const [localDocument, setLocalDocument] = useState(document);

  const [templateObject, setTemplateObject] = useState({});
  const [botObject, setBotObject] = useState({});
  const [expenseRows, setExpenseRows] = useState({});
  const [botExpenseRows, setBotExpenseRows] = useState({});
  const [lastClickedRow, setLastClickedRow] = useState(0);
  const [isViewSwitched, setIsViewSwitched] = useState(false);
  const [approvers, setApprovers] = useState([]);
  const [approverUpdationInProgress, setApproverUpdationInProgress] =
    useState(false);
  const [originalPoNumber, setOriginalPoNumber] = useState(null);
  const [purchaseOrder, purchaseOrderError, purchaseOrderLoading] =
    usePurchaseOrderDetails(originalPoNumber);
  const [poLines, setPoLines] = useState([]);
  const [documentKey, setDocumentKey] = useState(null);
  const [signedURL, docError, docIsLoading] = useSignedUrl(documentKey);

  const switchView = (fileURL) => {
    setIsViewSwitched(!isViewSwitched);
    if (fileURL !== null) {
      window.open(fileURL, '_blank');
    }
  };

  const onRowClick = useCallback((rowProps) => {
    setLastClickedRow(rowProps.rowIndex);
  }, []);

  useEffect(() => {
    const getApproversList = async () => {
      const approvers = await InvoiceApproverAdapter.getInvoiceAprovers();

      setApprovers(
        approvers.map((approver) => ({
          value: approver.email,
          name: approver.name,
          email: approver.email,
          limit: approver.approvalLimit,
          department: approver.department,
          label: `${approver.name} (${approver.email})`,
        })),
      );
    };

    getApproversList();
  }, []);

  useEffect(() => {
    if (document) {
      setLocalDocument(document);
      setOriginalPoNumber(document?.Document?.po_number);
      setDocumentKey(document?.file_name);

      const botObject = getBotObject(document);
      setBotObject(botObject);

      const templateObj = {
        matchedTemplateId: '',
        extraFields: {},
        botObject: { ...botObject },
        expenseRows: [],
        expenseRowsNew: [],
      };
      itemForm.setValues(templateObj); // New

      let rows = [];
      for (
        let index = 0;
        index < templateObj.botObject.lineItemData.length;
        index++
      ) {
        const element = templateObj.botObject.lineItemData[index];
        let rowItem = element;
        rowItem.id = index;
        rows.push(rowItem);
      }

      setExpenseRows(rows);
      setTemplateObject(templateObj);
    }
  }, [document]);

  useEffect(() => {
    const mergedPoLineItems = (purchaseOrder?.PurchaseOrders?.Lines || []).map(
      (incomingItem) => {
        const existingItem = localDocument?.Document?.po_line_items?.find(
          (item) => item.LineNum === incomingItem.LineNum,
        );

        if (existingItem) {
          // Merge the fields from the existing item to preserve fields like "Matches".
          return {
            ...existingItem,
            ...incomingItem,
          };
        }

        // If no matching item, add the new item as is
        return incomingItem;
      },
    );

    setPoLines(mergedPoLineItems);
  }, [purchaseOrder, localDocument]);

  const updateApproverDetails = useCallback(
    (approverEmail) => {
      const newApprover = approvers.find(
        (approver) => approver.value === approverEmail,
      );

      modals.openConfirmModal({
        title: 'Confirmation',
        children: (
          <Text size="sm">
            {`This action will set "${newApprover.name}" as an approver for this invoice. We will deliver the approval email to "${approverEmail}" upon your confirmation.`}
          </Text>
        ),
        labels: { confirm: 'Confirm', cancel: 'Cancel' },
        onCancel: () => {
          return false;
        },
        onConfirm: async () => {
          setApproverUpdationInProgress(true);

          const invoiceId = document._id;
          const approverEmail = newApprover.value;
          const approverName = newApprover.name;
          const supplierName = document.Document?.supplier?.name || '';
          const poNumber = document.Document?.po_number || '';
          const invoiceAmount = document.Document?.totals?.total || '';
          const invoiceNumber = document.Document?.invoice_number || '';
          const invoiceFileName = document.file_name || '';

          const payload = {
            invoiceId,
            approverEmail,
            approverName,
            supplierName,
            poNumber,
            invoiceAmount,
            invoiceNumber,
            invoiceFileName,
          };

          try {
            await InvoiceApproverAdapter.assignInvoiceApprover(payload);
          } catch (error) {
            console.log(
              '🚀 ~ file: DataGrid2.jsx:181 ~ onConfirm: ~ error:',
              error,
            );
            // TODO: Notify user about the action failure...
          } finally {
            setApproverUpdationInProgress(false);
            refetchDocument();
            // navigate('/');
          }
        },
      });
    },
    [approvers, document],
  );

  const onExpensesEditRemoveRow = useCallback(() => {
    if (lastClickedRow !== null) {
      let data = [...expenseRows];
      data.splice(lastClickedRow, 1);
      setExpenseRows(data);
      handleInputChange('expenseRows', data);
    }
  }, [expenseRows, lastClickedRow]);

  const onExpensesEditAddRow = useCallback(() => {
    let data = [...expenseRows];
    let location = data.length;
    let newRow = {
      LineNum: '',
      Code: '',
      Description: '',
      Quantity: '',
      UnitPrice: '',
      LineTotal: '',
      LineStatus: '',
      LineFormatCode: '',
    };
    newRow.id = location;
    data[location] = newRow;
    const lastItem = data.pop();
    data.splice(lastClickedRow, 0, lastItem);
    for (let index = 0; index < data.length; index++) {
      data[index].id = index;
    }
    setExpenseRows(data);
    handleInputChange('expenseRowsNew', data);
  }, [expenseRows, lastClickedRow]);

  useEffect(() => {
    if (lastClickedRow !== null) {
      // onExpensesEditAddRow(lastClickedRow);
    }
  }, [lastClickedRow, onExpensesEditAddRow]);

  const onExpensesEditComplete = useCallback(
    ({ value, columnId, rowId }) => {
      const data = [...expenseRows];
      // let dataList = [...expenseRowList];
      if (rowId === expenseRows.length) {
        rowId = rowId - 1;
      }
      console.log(data[rowId][columnId]);
      data[rowId][columnId] = value;

      setExpenseRows(data);
      handleInputChange('expenseRows', data);
    },
    [expenseRows],
  );

  const handlePurchaseOrderRowUpdate = (updatedRow) => {
    const poLineItems = localDocument.Document.po_line_items.map((line) => {
      return line.LineNum === updatedRow.LineNum
        ? { ...line, ...updatedRow }
        : line;
    });

    setLocalDocument({
      ...localDocument,
      Document: { ...localDocument.Document, po_line_items: poLineItems },
    });
  };

  const handleInputChange = (key, value) => {
    setTemplateObject({ ...templateObject, [key]: value });
  };

  const handleBotInputChange = (key, value) => {
    setBotObject({ ...botObject, [key]: value });
  };

  const submitOpenItem = async (item) => {
    toast.info('Busy processing case');
    let tempObject = templateObject;
    tempObject.botObject = botObject;
    tempObject.botObject.Expenses = botExpenseRows;
    let expenses = [];
    Object.keys(botObject).forEach((key) => {
      if (typeof botObject[key] === 'string' && botObject[key] === '') {
        toast.error(`Please enter ${key}`);
        return false;
      }
    });
    for (let index = 0; index < expenseRows.length; index++) {
      let element = expenseRows[index];
      delete element.id;
      const arrayFromObject = Object.values(element);
      for (let index = 0; index < arrayFromObject.length; index++) {
        const arrayElemnt = arrayFromObject[index];
        if (arrayElemnt !== '') {
          expenses.push(arrayFromObject);
          break;
        }
      }
    }

    tempObject.expenseRows = expenses;
    tempObject.botObject.lineItemData = expenseRows;

    const updatedDoc = getUpdatedDocObject(item, tempObject);
    updatedDoc.state = docStatusMap.zeroQ;

    let body = {
      transactionId: item._id,
      templateObject: updatedDoc,
    };

    await TransactionAdapter.updateTransaction(body);

    toast.success('Case successfully processed');

    // navigate('/');
    refetchDocument();
  };

  const pushBackItem = async (item) => {
    toast.info('Pushing back to the Manual Queue');

    let body = {
      transactionId: item._id,
      templateObject: {
        state: docStatusMap.manualQ,
      },
    };

    await TransactionAdapter.updateTransaction(body);
    toast.success('Pushed back succesfully');

    // navigate('/');
    refetchDocument();
  };

  const sqaushOpenItem = async (item) => {
    toast.info('Busy squashing case');

    let body = {
      transactionId: item._id,
      templateObject: {
        state: docStatusMap.squashQ,
      },
    };

    await TransactionAdapter.updateTransaction(body);

    toast.success('Case squashed succesfully');

    // navigate('/');
    refetchDocument();
  };

  const pushCurrentInvoiceToSapQ = async (invoiceId) => {
    if (!invoiceId) {
      return;
    }
    pushToSapQ([invoiceId]);
  };

  const pushToSapQ = async (invoiceIds) => {
    if (!Array.isArray(invoiceIds) || invoiceIds.length === 0) {
      return;
    }

    toast.info('Started pushing to SAP Queue', {
      type: toast.TYPE.INFO,
      autoClose: 3000,
    });

    try {
      await TransactionAdapter.pushToSapQ(invoiceIds);

      toast.info('Successfully pushed to SAP Queue', {
        type: toast.TYPE.SUCCESS,
        autoClose: 3000,
      });
    } catch (e) {
      console.error('Error in pushing to SAP Queue: ', e);

      toast.info('Something went wrong while pushing to SAP Queue', {
        type: toast.TYPE.ERROR,
        autoClose: 3000,
      });
    } finally {
      navigate('/');
    }
  };

  let itemForm = useForm({
    initialValues: {},
    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
    },
  });

  /**
   * Transforms the object structure to the current one.
   * This transformation aims to retrofit the implementation and
   * should be removed/improved later.
   */
  function getBotObject(doc) {
    const invoiceReceivedDate = new Date(doc.date_added)
      .toISOString()
      .split('T')[0];
    const {
      document_type,
      account_number,
      invoice_number,
      po_number,
      invoice_date,
    } = doc.Document;
    const { name: supplierName, vat_number } = doc.Document.supplier;
    const { total } = doc.Document.totals;
    const lineItems = (doc.Document.line_items || []).map((lineItem, index) => {
      return {
        LineNum: index,
        Code: lineItem.code,
        Description: lineItem.description,
        Quantity: lineItem.quantity,
        UnitPrice: lineItem.unit_rate,
        LineTotal: lineItem.total,
        Discount: lineItem.discount || 0,
        LineStatus: '',
        LineFormatCode: '',
      };
    });

    return {
      Document_Type: document_type || '',
      Account_Number: account_number || '',
      Invoice_Number: invoice_number || '',
      PO_Number: po_number || '',
      Supplier: supplierName || '',
      Invoice_Date: invoice_date || '',
      Invoice_Received_Date: invoiceReceivedDate || '',
      Total_Amount: (total || total === 0 ? total : '').toString(),
      Vat_Number: vat_number || '',
      lineItemData: lineItems,
      Expenses: [],
    };
  }

  /**
   * Transforms the object structure back to the new "document" structure.
   * This transformation aims to retrofit the implementation and
   * should be removed/improved later.
   */
  function getUpdatedDocObject(doc, bot) {
    const updatedDoc = { ...doc };

    updatedDoc.Document.document_type = bot.botObject.Document_Type;
    updatedDoc.Document.account_number = bot.botObject.Account_Number;
    updatedDoc.Document.invoice_number = bot.botObject.Invoice_Number;
    updatedDoc.Document.po_number = bot.botObject.PO_Number;
    updatedDoc.Document.supplier.name = bot.botObject.Supplier;
    updatedDoc.Document.supplier.vat_number = bot.botObject.Vat_Number;
    updatedDoc.Document.invoice_date = bot.botObject.Invoice_Date;
    updatedDoc.date_added = bot.botObject.Invoice_Received_Date;
    updatedDoc.Document.totals.total = bot.botObject.Total_Amount;
    updatedDoc.Document.line_items = (bot.botObject.lineItemData || []).map(
      (lineItem) => {
        return {
          code: lineItem.Code,
          description: lineItem.Description,
          quantity: lineItem.Quantity,
          unit_rate: lineItem.UnitPrice,
          total: lineItem.LineTotal,
        };
      },
    );

    return updatedDoc;
  }

  /**
   * Sets the `localDocument` state to the selected document version.
   * If user clears the document version history dropdown then
   * binds the `localDocument` to the original `document`.
   *
   * @param selectedDocVersion - selected document object. It will be "" when user
   * clears the Document Version History dropdown to go back to the current version.
   */
  const handleDocHistorySelection = (selectedDocVersion) => {
    const selectedDoc = selectedDocVersion || document;
    let rows = [];

    setLocalDocument(selectedDoc);
    const botObject = getBotObject(selectedDoc);
    setBotObject(botObject);

    const templateObj = {
      matchedTemplateId: '',
      extraFields: {},
      botObject: { ...botObject },
      expenseRows: [],
      expenseRowsNew: [],
    };
    itemForm.setValues(templateObj);

    for (
      let index = 0;
      index < templateObj.botObject.lineItemData.length;
      index++
    ) {
      const element = templateObj.botObject.lineItemData[index];
      let rowItem = element;
      rowItem.id = index;
      rows.push(rowItem);
    }

    setExpenseRows(rows);
    setTemplateObject(templateObj);
  };

  const appendPurchaseOrderTotal = (tabTitle = '') => {
    const total = formatCurrency(purchaseOrder?.PurchaseOrders?.DocTotal);
    return total ? `${tabTitle} - ${total}` : tabTitle;
  };

  if (isLoading || !localDocument) {
    return (
      <Container
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
        px="xs">
        <Loader color="cyan" />
      </Container>
    );
  }

  return (
    <SimpleGrid cols={isViewSwitched === true ? 1 : 2}>
      <Card shadow="sm" radius="md">
        <Group justify="space-between">
          <Button
            type="button"
            leftIcon={<IconArrowLeft size={14}></IconArrowLeft>}
            onClick={() => navigate('/')}>
            GO BACK
          </Button>
          {isViewSwitched === true ? (
            <Button
              type="button"
              variant="gradient"
              gradient={{ from: 'yellow', to: 'yellow', deg: 60 }}
              onClick={() => switchView(null)}>
              SWITCH VIEW
            </Button>
          ) : null}
        </Group>

        <Group position="apart">
          <Stack spacing="none">
            <Title order={3}>Invoice Details </Title>
            <Text color="gray">{docStatusNameMap[document.state]}</Text>
          </Stack>

          {hideDocHistoryFrom.includes(document.state) ? (
            <></>
          ) : (
            <DocHistoryDropdown
              sourceId={document._id}
              onSelect={handleDocHistorySelection}></DocHistoryDropdown>
          )}
        </Group>

        <Divider my="sm" />

        {/* Error Message Alert */}
        {localDocument.message && (
          <Alert
            title="Error"
            variant="light"
            color="red"
            icon={<IconInfoCircle />}>
            {localDocument.message}
          </Alert>
        )}

        {/* Service Invoice Approver Alert - No Approver Selected */}
        {document.state === docStatusMap.serviceInvoiceQ &&
          !document.approver &&
          !approverUpdationInProgress && (
            <Alert
              title="Approval Required"
              variant="light"
              color="blue"
              my="md"
              icon={<IconInfoCircle />}>
              This service invoice needs to be reviewed by an authorized
              approver before it can proceed. Please select an approver from the
              list to ensure timely processing.
            </Alert>
          )}

        {/* Service Invoice Approver Alert - Approver has approved. */}
        {[
          docStatusMap.batchQ,
          docStatusMap.sapWaitingQ,
          docStatusMap.completeQ,
        ].includes(document.state) &&
          document.approver &&
          !approverUpdationInProgress &&
          document?.approver?.has_approved && (
            <Alert
              title="Approved Invoice"
              variant="light"
              color="green"
              my="md"
              icon={<IconInfoCircle />}>
              <Text>
                {`This invoice has been approved by ${document.approver.name} (${document.approver.email}).`}
              </Text>
            </Alert>
          )}

        {/* Service Invoice Approver Alert - Approver has rejected. */}
        {[docStatusMap.rejectedQ].includes(document.state) &&
          document.approver &&
          !approverUpdationInProgress &&
          document?.approver?.remarks &&
          !document?.approver?.has_approved && (
            <Alert
              title="Rejection Reason"
              variant="light"
              color="red"
              my="md"
              icon={<IconInfoCircle />}>
              <Text>{`${document.approver.remarks}`}</Text>
            </Alert>
          )}

        {/* Service Invoice Approver Alert - Has approver but not approved. */}
        {document.state === docStatusMap.serviceInvoiceQ &&
          document.approver &&
          !document.approver?.has_approved &&
          !approverUpdationInProgress && (
            <Alert
              title="Awaiting Approval"
              variant="light"
              color="orange"
              my="md">
              <p>
                <Text>
                  {`This invoice requires an approval from ${document.approver.name} (${document.approver.email}).`}
                </Text>
              </p>

              <Tooltip
                withArrow
                label={`This will resend invoice approval email to "${document.approver.email}".`}>
                <Button
                  type="button"
                  color="orange"
                  variant="filled"
                  leftIcon={<IconMailFast />}
                  onClick={() =>
                    updateApproverDetails(document?.approver?.email)
                  }>
                  RESEND APPROVAL
                </Button>
              </Tooltip>
            </Alert>
          )}

        {/* Service Invoice Approver Alert - Approver updation request in progress. */}
        {(document.state === docStatusMap.serviceInvoiceQ ||
          document.state === docStatusMap.rejectedQ) &&
          approverUpdationInProgress && (
            <Alert
              title="Setting Approver"
              variant="light"
              color="blue"
              my="md"
              icon={<Loader color="blue" size="xs" />}>
              Saving the invoice approver details.
            </Alert>
          )}

        <Card.Section inheritPadding py="md">
          <form onSubmit={itemForm.onSubmit((values) => console.log(values))}>
            <SimpleGrid cols={isViewSwitched === true ? 4 : 3}>
              {/* Invoice Details Fields */}
              {Object.keys(botObject).map(
                (key) =>
                  typeof botObject[key] === 'string' &&
                  key !== 'approver' && (
                    <TextInput
                      key={key}
                      id={key + '_input'}
                      label={key.toUpperCase().replaceAll('_', ' ')}
                      disabled={
                        editableViews.includes(document.state) ? false : true
                      }
                      value={botObject[key] || ''}
                      error={
                        !botObject[key]
                          ? 'Please set ' +
                            key.toUpperCase().replaceAll('_', ' ') +
                            '.'
                          : ''
                      }
                      onChange={(e) =>
                        handleBotInputChange(key, e.target.value)
                      }
                      withAsterisk
                    />
                  ),
              )}

              {/* Invoice Approver Dropdown */}
              {(document.state === docStatusMap.serviceInvoiceQ ||
                document.state === docStatusMap.rejectedQ) && (
                <Select
                  id="approver_select"
                  label="APPROVER"
                  placeholder="Select an approver"
                  data={approvers.filter((approver) => {
                    // Exclude approvers whose Approval Limit < Invoice Total.
                    return approver.limit >= Number(botObject['Total_Amount']);
                  })}
                  itemComponent={SelectApproverItem}
                  value={document.approver?.email || ''}
                  onChange={updateApproverDetails}
                  searchable
                  nothingFound="No match found..."
                  withAsterisk
                />
              )}
            </SimpleGrid>
          </form>
        </Card.Section>

        {/* Invoice remarks. */}
        {document.Document?.remarks && (
          <Alert title="Invoice Remarks" variant="outline" color="gray" mb="md">
            <Text size="sm">{document.Document.remarks}</Text>
          </Alert>
        )}

        {/* TODO: This should be extracted to a separate component. */}
        <Tabs
          variant="default"
          defaultValue={
            document.Document?.po_number ? 'poLines' : 'invoiceLines'
          }>
          <Tabs.List>
            <Tabs.Tab value="invoiceLines">Invoice Lines</Tabs.Tab>
            <Tabs.Tab value="poLines">
              {appendPurchaseOrderTotal('Purchase Order')}
            </Tabs.Tab>
          </Tabs.List>
          <Tabs.Panel value="invoiceLines" pt="xs">
            {/* Add Line / Remove Line Buttons */}
            {editableViews.includes(document.state) && (
              <Group position="right" justify="space-between" mb="xs">
                <Button
                  onClick={() => onExpensesEditAddRow(0)}
                  id={`button-AddLine`}>
                  ADD LINE
                </Button>
                <Button
                  onClick={() => onExpensesEditRemoveRow(0)}
                  variant="gradient"
                  gradient={{ from: 'orange', to: 'orange', deg: 60 }}>
                  REMOVE LINE
                </Button>
              </Group>
            )}

            {/* Invoice Line Items Grid */}
            <ReactDataGrid
              idProperty="id"
              style={{ minHeight: 300 }}
              onEditComplete={onExpensesEditComplete}
              editable={editableViews.includes(document.state) ? true : false}
              columns={expenseColumn}
              dataSource={expenseRows}
              onRowClick={onRowClick}
            />
          </Tabs.Panel>
          <Tabs.Panel value="poLines" pt="xs">
            <PurchaseOrderLines
              style={{ minHeight: 300 }}
              poLines={poLines}
              isLoading={purchaseOrderLoading}
              error={purchaseOrderError}
              onRowUpdate={handlePurchaseOrderRowUpdate}
            />
          </Tabs.Panel>
        </Tabs>

        <Divider my="sm" />

        <Grid>
          <Grid.Col span={6}>
            <Group position="left">
              {pushBackViews.includes(document.state) && (
                <Tooltip
                  withArrow
                  label="Moves this transaction back to Manual Queue.">
                  <Button
                    variant="gradient"
                    gradient={{ from: 'orange', to: 'orange', deg: 60 }}
                    type="button"
                    onClick={() => pushBackItem(document)}>
                    PUSH BACK
                  </Button>
                </Tooltip>
              )}
              {squashViews.includes(document.state) ? (
                <Tooltip
                  withArrow
                  label="Permanently discards and moves this record to the Squashed Queue.">
                  <Button
                    variant="gradient"
                    gradient={{ from: 'red', to: 'orange', deg: 60 }}
                    type="button"
                    onClick={() => sqaushOpenItem(document)}>
                    SQUASH
                  </Button>
                </Tooltip>
              ) : null}
            </Group>
          </Grid.Col>
          <Grid.Col span={6}>
            <Group position="right">
              {/* Update & Cancel Buttons */}
              {editableViews.includes(document.state) && (
                <Tooltip
                  withArrow
                  label="Moves this record to Zero Queue to validate your changes.">
                  <Button
                    variant="gradient"
                    gradient={{ from: 'green', to: 'green', deg: 60 }}
                    type="submit"
                    onClick={() => submitOpenItem(localDocument)}>
                    UPDATE
                  </Button>
                </Tooltip>
              )}

              {/* Process Transaction Button */}
              {document.state === docStatusMap.batchQ && (
                <Tooltip
                  withArrow
                  label="Moves this transaction to SAP Waiting Queue.">
                  <Button
                    variant="gradient"
                    gradient={{ from: 'green', to: 'green', deg: 60 }}
                    type="submit"
                    onClick={() => pushCurrentInvoiceToSapQ(document._id)}>
                    PROCESS TRANSACTION
                  </Button>
                </Tooltip>
              )}
            </Group>
          </Grid.Col>
        </Grid>
      </Card>
      <>
        {isViewSwitched === false ? (
          <PDFViewer
            fileURL={signedURL}
            loading={docIsLoading}
            error={docError}
            switchView={switchView}
          />
        ) : null}
      </>
    </SimpleGrid>
  );
}

export default DocumentDetails;
