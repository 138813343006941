import httpClient from '../utils/httpClient';
import GLOBAL from '../config/global';
import { useQuery } from '@tanstack/react-query';

function useSignedUrl(documentKey) {
  const { data, error, isLoading } = useQuery({
    queryKey: ['signedUrl', documentKey],

    queryFn: () => fetchSignedUrl(documentKey),

    // Only run the query if documentKey is truthy
    enabled: !!documentKey,

    staleTime: 5 * 60 * 1000,
  });

  return [data, error, isLoading];
}

async function fetchSignedUrl(documentKey) {
  const body = {
    documentKey: documentKey,
  };
  const response = await httpClient.post(
    `${GLOBAL.API_URL}/getSignedUrl`,
    body,
  );
  const blob = base64toBlob(response.data);
  const url = URL.createObjectURL(blob);
  return url;
}

function base64toBlob(base64Data) {
  const bytes = atob(base64Data);
  let length = bytes.length;
  let out = new Uint8Array(length);

  while (length--) {
    out[length] = bytes.charCodeAt(length);
  }

  return new Blob([out], { type: 'application/pdf' });
}

export default useSignedUrl;
