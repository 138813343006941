import httpClient from '../utils/httpClient';
import GLOBAL from '../config/global';
import { useQuery, useQueryClient } from '@tanstack/react-query';

function useFetchInvoiceApprovers() {
  const queryClient = useQueryClient();

  const { data, error, isLoading } = useQuery({
    queryKey: ['invoiceApprovers'],

    queryFn: () => fetchUsers(),

    staleTime: 5 * 60 * 1000,
  });

  const invalidateCache = () => {
    queryClient.invalidateQueries(['invoiceApprovers']);
  };

  const refetch = () => {
    queryClient.refetchQueries(['invoiceApprovers']);
  };

  return [data, error, isLoading, invalidateCache, refetch];
}

async function fetchUsers() {
  const response = await httpClient.get(
    `${GLOBAL.API_URL}/getInvoiceApprovers`,
  );
  return response.data.message;
}

export default useFetchInvoiceApprovers;
