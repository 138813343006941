/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { Box, Image, Skeleton } from '@mantine/core';
import {
  AppShell,
  Navbar,
  useMantineTheme,
  Loader,
  Center,
} from '@mantine/core';
import { IconSettingsAutomation, IconManualGearbox } from '@tabler/icons-react';

//Images
import logo from '../assets/images/logo/CMYK/logo/png/Juice_Primary_Logo.png';

//config

// Custom Components
import { UserMenu } from '../components/_userMenu';
import NavigationLinks from '../components/NavigationLinks';
import AdminDataView from '../components/AdminDataView';

// Custom Adappters
import ConfigAdapter from '../adapters/configAdapter';
import configViews from '../constants/configViews';
import InvoiceApprovers from '../pages/InvoiceApprovers';

function AdminLayout({ setTheme }) {
  const theme = useMantineTheme();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [view, setView] = useState('');
  const [transactionViews, setTransactionViews] = useState([]);
  const [navbarViewData, setNavbarViewData] = useState([]);
  const [logoIsLoading, setLogoIsLoading] = useState(true);

  useEffect(() => {
    getViewDetails();
  }, []);

  useEffect(() => {
    // As we want to show "Users Management" tab as the initially
    // selected tab/screen when `navbarViewData` (nav menu items) is loaded.
    loadDefaultQueue();
  }, [navbarViewData]);

  const loadDefaultQueue = () => {
    if (navbarViewData.length) {
      const defaultView = 'Users';
      fetchData(defaultView);
      setView(defaultView);
    }
  };

  const getViewDetails = async () => {
    const companyId = localStorage.getItem('companyId');
    let viewDetails = await ConfigAdapter.getConfigViews(companyId);
    setTransactionViews(viewDetails);

    let navbarData = [];
    //set navbarData
    for (let index = 0; index < viewDetails.length; index++) {
      const element = viewDetails[index];
      if (
        element.navBarDetails !== null &&
        element.navBarDetails !== undefined
      ) {
        let object = element.navBarDetails;
        object.icon = getIconByView(element.navBarDetails.label);
        object.queueOrder = element.queueOrder;
        navbarData.push(element.navBarDetails);
      }
    }

    const sortedQueue = orderByNumericProperty(navbarData, 'queueOrder');

    setNavbarViewData(sortedQueue);
  };

  function orderByNumericProperty(arr, prop) {
    return arr.sort((a, b) => a[prop] - b[prop]);
  }

  const getIconByView = (viewLabel) => {
    switch (viewLabel) {
      case 'User Management':
        return IconManualGearbox;
      case 'View Management':
        return IconSettingsAutomation;
      default:
        return IconManualGearbox;
    }
  };

  const fetchData = async (newView) => {
    if (newView === undefined) {
      newView = view;
    }
    setIsLoading(true);
    const data = {
      client: localStorage.getItem('companyId'),
      model: newView,
    };
    const foundTransactions = await ConfigAdapter.getConfigDetails(data);
    setData(foundTransactions);
    setIsLoading(false);
  };

  const handleNavLinkClick = (index) => {
    fetchData(navbarViewData[index].modelLink);
    setView(navbarViewData[index].modelLink);
  };

  const setDefaultView = () => {
    window.location.href = './transactions';
  };

  return (
    <AppShell
      styles={{
        main: {
          background:
            theme.colorScheme === 'dark'
              ? theme.colors.dark[8]
              : theme.colors.gray[1],
        },
      }}
      navbarOffsetBreakpoint="sm"
      asideOffsetBreakpoint="sm"
      navbar={
        <Navbar
          hiddenBreakpoint="sm"
          hidden={true}
          width={{ sm: 150, lg: 200 }}>
          <Navbar.Section grow mt="md">
            <Skeleton
              height={50}
              mx="auto"
              width="90%"
              radius="md"
              visible={logoIsLoading}>
              <Image
                maw={150}
                mx="auto"
                radius="md"
                src={logo}
                alt="Logo"
                onClick={setDefaultView}
                onLoad={() => setLogoIsLoading(false)}
              />
            </Skeleton>
            <br />
            {navbarViewData.length > 0 && (
              <NavigationLinks
                setView={handleNavLinkClick}
                navbarData={navbarViewData}
              />
            )}

            {/* Shows nav-menu skeleton when loading... */}
            {navbarViewData.length === 0 && (
              <Box mx="sm">
                <Skeleton height={35} mb="sm" />
                <Skeleton height={35} mb="sm" />
                <Skeleton height={35} mb="sm" />
                <Skeleton height={35} mb="sm" />
                <Skeleton height={35} mb="sm" />
                <Skeleton height={35} mb="sm" />
                <Skeleton height={35} mb="sm" />
              </Box>
            )}
          </Navbar.Section>
          <Navbar.Section>
            <UserMenu setTheme={setTheme} />
          </Navbar.Section>
        </Navbar>
      }>
      <>
        {!isLoading ? (
          <>
            {view === configViews.invoiceApproversConfig ? (
              <InvoiceApprovers />
            ) : (
              <AdminDataView
                data={data}
                view={view}
                transactionViews={transactionViews}
              />
            )}
          </>
        ) : (
          <Center maw={400} h={100} mx="auto">
            <Loader color="cyan" />
          </Center>
        )}
      </>
    </AppShell>
  );
}

export default AdminLayout;
