const formatCurrency = (amount, locale = 'en-ZA', currency = 'ZAR') => {
  if (isNaN(amount)) return '';

  return Number(amount).toLocaleString(locale, {
    style: 'currency',
    currency,
  });
};

export default formatCurrency;
