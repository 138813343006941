import { useEffect, useState, useCallback } from 'react';
import { Loader, Center, Text, Checkbox } from '@mantine/core';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import filter from '@inovua/reactdatagrid-community/filter';
import '@inovua/reactdatagrid-community/index.css';

// https://reactdatagrid.io/docs/filtering#filter-types-&-operators
const defaultFilterValue = [
  { name: 'code', operator: 'contains', type: 'string', value: '' },
  { name: 'name', operator: 'contains', type: 'string', value: '' },
  { name: 'taxId', operator: 'contains', type: 'string', value: '' },
  { name: 'email', operator: 'contains', type: 'string', value: '' },
];

const useFilteredVendors = (vendors, filterValue) => {
  const applyFilter = useCallback(() => {
    if (filterValue.every((f) => f.value === '')) {
      // Restore original data if all filters are empty
      return vendors;
    } else {
      // Apply filters to the vendors
      return filter(vendors, filterValue);
    }
  }, [vendors, filterValue]);

  return applyFilter;
};

function VendorsList({ vendors, error, loading, onVendorSelected, onEdit }) {
  const [filteredVendors, setFilteredVendors] = useState(vendors);
  const [filterValue, setFilterValue] = useState(defaultFilterValue);

  // Centralized filter logic
  const applyFilter = useFilteredVendors(vendors, filterValue);

  const gridColumns = [
    {
      name: 'preferred',
      header: 'Preferred',
      minWidth: 100,
      editable: false,
      render: ({ value, data }) => {
        return (
          <Checkbox
            checked={!!value}
            onChange={(event) => {
              const { _id, taxId } = data;

              onVendorSelected({
                vendorId: _id,
                taxId: taxId,
                preferred: event.target.checked,
              });
            }}
          />
        );
      },
    },
    {
      name: 'code',
      header: 'Vendor Code',
      defaultFlex: 1,
      minWidth: 80,
    },
    {
      name: 'name',
      header: 'Vendor Name',
      defaultFlex: 2,
      minWidth: 100,
    },
    {
      name: 'taxId',
      header: 'VAT #',
      defaultFlex: 1,
      minWidth: 80,
    },
    {
      name: 'email',
      header: 'Email',
      defaultFlex: 1,
      minWidth: 100,
    },
  ];

  useEffect(() => {
    setFilteredVendors(applyFilter());
  }, [applyFilter]);

  const onFilterValueChange = useCallback(
    (newFilterValue) => {
      setFilterValue(newFilterValue);
      setFilteredVendors(applyFilter());
    },
    [applyFilter],
  );

  if (loading) {
    return (
      <Center style={{ height: '100vh' }}>
        <Loader color="cyan" />
      </Center>
    );
  }

  if (error) {
    return (
      <Center style={{ height: '100vh' }}>
        <Text color="red">Failed to fetch vendors</Text>
      </Center>
    );
  }

  return (
    <ReactDataGrid
      idProperty="_id"
      style={{ minHeight: 'calc(100dvh - 190px)' }}
      onFilterValueChange={onFilterValueChange}
      filterValue={filterValue}
      editable={true}
      onEditComplete={onEdit}
      columns={gridColumns}
      dataSource={filteredVendors}
      pagination={true}
      defaultLimit={15}
      pageSizes={[5, 10, 15, 20]}
    />
  );
}

export default VendorsList;
