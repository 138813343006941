import {
  Box,
  Button,
  Container,
  Group,
  Stack,
  Text,
  Textarea,
  Title,
} from '@mantine/core';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import InvoiceApproverAdapter from '../adapters/invoiceApproversAdapter';
import { useForm } from '@mantine/form';

const RejectInvoice = () => {
  const [searchParams] = useSearchParams();
  const [submissionInProgress, setSubmissionInProgress] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [error, setError] = useState(null);
  const [invoiceId, setInvoiceId] = useState(null);
  const [token, setToken] = useState(null);

  const form = useForm({
    initialValues: {
      remarks: '',
    },
  });

  useEffect(() => {
    const { invoice_id, token } = Object.fromEntries([...searchParams]);

    if (!invoice_id || !token) {
      setError('Invalid invoice Id or token.');
    } else {
      setInvoiceId(invoice_id);
      setToken(token);
    }
  }, [searchParams]);

  const handleSubmit = async (values) => {
    if (submissionInProgress) return;

    setSubmissionInProgress(true);

    try {
      await InvoiceApproverAdapter.processInvoiceRejection({
        invoice_id: invoiceId,
        token,
        remarks: values.remarks,
      });
    } catch (err) {
      console.log(
        '🚀 ~ file: RejectInvoice.jsx:75 ~ handleSubmit ~ error:',
        err,
      );
      setError(err.message);
    } finally {
      setSubmissionInProgress(false);
      setIsSubmitted(true);
    }
  };

  return (
    <Container
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}>
      <Stack align="center">
        {/* Platform Logo */}
        <img
          src="https://new.proteacoin.link/static/media/Juice_Primary_Logo.e958107328f60c9a5925.png"
          height={72}
          alt=""
        />

        {/* Error state. */}
        {error && (
          <>
            <Title order={2} color="red">
              Something went wrong!
            </Title>
            <Text>{error}</Text>
          </>
        )}

        {/* Submitted state. */}
        {!error && isSubmitted && (
          <>
            <Title order={2} color="teal">
              Approval status updated successfully!
            </Title>
          </>
        )}

        {!error && !isSubmitted && (
          <Box maw={600} mx="auto">
            <form onSubmit={form.onSubmit(handleSubmit)}>
              <Textarea
                {...form.getInputProps('remarks')}
                label="Remarks (Optional)"
                description="Please describe the rejection reason as a note to other stakeholders."
                autoCapitalize=""
                minRows={3}
                autosize
              />
              <Group position="center" mt="md">
                <Button
                  type="submit"
                  variant="filled"
                  color="red"
                  fullWidth
                  loading={submissionInProgress}>
                  {submissionInProgress ? 'REJECTING' : 'REJECT'}
                </Button>
              </Group>
            </form>
          </Box>
        )}
      </Stack>
    </Container>
  );
};

export default RejectInvoice;
