import { useEffect } from 'react';
import ChartsEmbedSDK from '@mongodb-js/charts-embed-dom';
import httpClient from '../utils/httpClient';
import GLOBAL from '../config/global';

const DASHBOARD_URL = 'https://charts.mongodb.com/charts-protea-coin-lkxvlqs';
const DASHBOARD_ID = '1663e5a0-afd7-4801-8469-80df7a00f93b';

function Dashboard() {
  const getAccessToken = () => {
    return localStorage.getItem('accessToken');
  };

  useEffect(() => {
    const loadChart = async () => {
      // Reference -
      // https://www.mongodb.com/docs/charts/get-started-embedding-sdk/#std-label-get-started-embedding-sdk

      const sdk = new ChartsEmbedSDK({
        baseUrl: DASHBOARD_URL,
        getUserToken: () => getAccessToken(),
      });

      const dashboard = sdk.createDashboard({
        dashboardId: DASHBOARD_ID,
        widthMode: 'scale',
        heightMode: 'scale',
        showAttribution: false,
      });

      try {
        await dashboard.render(document.getElementById('dashboard-container'));
      } catch (err) {
        console.error('Error rendering dashboard: ', err);

        // Possibly accessToken would be expired.
        // Attempt to refresh the accessToken, it'll redirect to
        // login if refreshToken is also expired.
        await httpClient.post(`${GLOBAL.API_URL}/refreshToken`);
      }
    };

    loadChart();
  }, []);

  return (
    <div
      id="dashboard-container"
      style={{ height: '100%', margin: '0px -16px' }}></div>
  );
}

export default Dashboard;
