import { Button, TextInput, Stack } from '@mantine/core';
import { isEmail, isNotEmpty, useForm } from '@mantine/form';

import InvoiceApproverAdapter from '../adapters/invoiceApproversAdapter';
import { useState } from 'react';

const InvoiceApproverForm = ({ onSuccess, onError }) => {
  const [submissionInProgress, setSubmissionInProgress] = useState(false);

  const form = useForm({
    initialValues: {
      email: '',
      name: '',
      department: '',
      approvalLimit: 5000,
    },
    validate: {
      email: isEmail('Invalid email address'),
      name: isNotEmpty('Name is required'),
      approvalLimit: isNotEmpty('Approval limit is required'),
    },
    validateInputOnBlur: true,
  });

  const handleSubmit = async (values) => {
    if (submissionInProgress) return;

    setSubmissionInProgress(true);

    try {
      const response = await InvoiceApproverAdapter.setInvoiceApprover(values);
      onSuccess(response);
    } catch (error) {
      console.log(
        '🚩 ~ file: InvoiceApproverForm.jsx:27 ~ handleSubmit ~ error:',
        error,
      );
      onError(error);
    } finally {
      setSubmissionInProgress(false);
    }
  };

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <Stack>
        <TextInput
          label="Email"
          {...form.getInputProps('email')}
          onBlur={() => form.validateField('email')}
          error={form.errors.email}
          required
        />
        <TextInput label="Name" {...form.getInputProps('name')} required />
        <TextInput label="Department" {...form.getInputProps('department')} />
        <TextInput
          label="Approval Limit"
          {...form.getInputProps('approvalLimit')}
        />
      </Stack>
      <Button
        type="submit"
        mt="md"
        loading={submissionInProgress}
        loaderProps={{ type: 'dots' }}>
        {submissionInProgress ? 'Submitting' : 'Submit'}
      </Button>
    </form>
  );
};

export default InvoiceApproverForm;
