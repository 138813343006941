import { useEffect, useState, useCallback } from 'react';
import { Loader, Center, Text } from '@mantine/core';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import filter from '@inovua/reactdatagrid-community/filter';
import '@inovua/reactdatagrid-community/index.css';

const gridColumns = [
  {
    name: 'email',
    header: 'Email',
    defaultFlex: 1,
    minWidth: 100,
    editable: false,
  },
  { name: 'name', header: 'Name', defaultFlex: 1, minWidth: 100 },
  {
    name: 'approvalLimit',
    header: 'Approval Limit',
    defaultFlex: 1,
    minWidth: 100,
    render: ({ value }) =>
      value ? new Intl.NumberFormat().format(value) : value,
  },
  { name: 'department', header: 'Department', defaultFlex: 1, minWidth: 100 },
];

// https://reactdatagrid.io/docs/filtering#filter-types-&-operators
const defaultFilterValue = [
  { name: 'email', operator: 'contains', type: 'string', value: '' },
  { name: 'name', operator: 'contains', type: 'string', value: '' },
  { name: 'approvalLimit', operator: 'gte', type: 'number' },
  { name: 'department', operator: 'startsWith', type: 'string', value: '' },
];

const useFilteredApprovers = (approvers, filterValue) => {
  const applyFilter = useCallback(() => {
    if (filterValue.every((f) => f.value === '')) {
      // Restore original data if all filters are empty
      return approvers;
    } else {
      // Apply filters to the approvers
      return filter(approvers, filterValue);
    }
  }, [approvers, filterValue]);

  return applyFilter;
};

function InvoiceApproversList({ approvers, error, loading, onEdit }) {
  const [filteredApprovers, setFilteredApprovers] = useState(approvers);
  const [filterValue, setFilterValue] = useState(defaultFilterValue);

  // Centralized filter logic
  const applyFilter = useFilteredApprovers(approvers, filterValue);

  useEffect(() => {
    setFilteredApprovers(applyFilter());
  }, [applyFilter]);

  const onFilterValueChange = useCallback(
    (newFilterValue) => {
      setFilterValue(newFilterValue);
      setFilteredApprovers(applyFilter());
    },
    [applyFilter],
  );

  if (loading) {
    return (
      <Center style={{ height: '100vh' }}>
        <Loader color="cyan" />
      </Center>
    );
  }

  if (error) {
    return (
      <Center style={{ height: '100vh' }}>
        <Text color="red">Failed to fetch invoice approvers</Text>
      </Center>
    );
  }

  return (
    <ReactDataGrid
      idProperty="_id"
      style={{ minHeight: 'calc(100dvh - 190px)' }}
      onFilterValueChange={onFilterValueChange}
      filterValue={filterValue}
      onEditComplete={onEdit}
      editable={true}
      columns={gridColumns}
      dataSource={filteredApprovers}
      pagination={true}
      defaultLimit={15}
      pageSizes={[5, 10, 15, 20]}
    />
  );
}

export default InvoiceApproversList;
