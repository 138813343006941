import httpClient from '../utils/httpClient';
import GLOBAL from '../config/global';
import { useQuery, useQueryClient } from '@tanstack/react-query';

function useFetchVendors() {
  const queryClient = useQueryClient();

  const { data, error, isLoading } = useQuery({
    queryKey: ['vendors'],

    queryFn: () => fetchVendors(),

    staleTime: 5 * 60 * 1000,
  });

  const invalidateCache = () => {
    queryClient.invalidateQueries(['vendors']);
  };

  const refetch = () => {
    queryClient.refetchQueries(['vendors']);
  };

  return [data, error, isLoading, invalidateCache, refetch];
}

async function fetchVendors() {
  const response = await httpClient.get(`${GLOBAL.API_URL}/getVendors`);
  return response.data.message;
}

export default useFetchVendors;
