const docStatusNameMap = Object.freeze({
  'b56e27c6-c2cb-4a6a-b6ad-9029a4e6bbea': 'Dashboard',
  'a94af708-2180-41d1-97b5-5d6f56be1e06': 'Zero Queue',
  'a94af708-2180-41d1-97b5-5a6d56be1e06': 'Manual Queue',
  '093f5591-04af-4077-9046-5a41c9fa8289': 'Queries Queue',
  '17b8f132-ad0d-4697-bcd1-77d97a4d2958': 'PO No Match Queue',
  'a44759d6-10b5-413e-a6c2-d2a638e086c7': 'Complete Queue',
  '40504ac6-c0c6-4d3e-b1fb-78668c36986b': 'Batch Queue',
  'db1b46a7-7489-40f2-b597-43a9af74dae5': 'No GRV Queue',
  '7ae59c80-4231-46b9-8f8e-2190115f4988': 'Service Invoice Queue',
  '9eb0ad37-1ae1-4fdf-8211-388ce7649c57': 'Squashed Queue',
  '8759c806-1626-4bdf-af17-c1424368943e': 'SAP Waiting Queue',
  'e3eeb590-4a2a-41d8-96a2-8e9de6f2fcdd': 'Duplicate Queue',
  'd641af59-aa76-43bd-a06b-92f75a34e0c4': 'Rejected Queue',
});

export default docStatusNameMap;
