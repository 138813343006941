import { useState, useEffect } from 'react';
import httpClient from '../utils/httpClient';
import GLOBAL from '../config/global';

function useDocHistory(docId) {
  const [docHistory, setDocHistory] = useState([]);

  useEffect(() => {
    async function getDocHistory(docId) {
      if (!docId) return;

      try {
        const response = await httpClient.get(
          `${GLOBAL.API_URL}/getLogs/${docId}`,
        );
        setDocHistory(response.data.message);
      } catch (error) {
        console.error('Error fetching document history:', error);
      }
    }

    getDocHistory(docId);
  }, [docId]);

  return docHistory;
}

export default useDocHistory;
