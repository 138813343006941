import axios from 'axios';
import GLOBAL from '../config/global';

const getConfigViews = async (companyId) => {
  const body = {
    companyId: companyId,
  };
  const rtnObj = await axios.post(`${GLOBAL.API_URL}/getConfigViews`, body);
  return rtnObj.data.message;
};

const getConfigDetails = async (data) => {
  const body = {
    client: data.client,
    model: data.model,
  };
  const rtnObj = await axios.post(`${GLOBAL.API_URL}/getConfigDetails`, body);
  return rtnObj.data.message;
};

const ConfigAdapter = {
  getConfigViews,
  getConfigDetails,
};

export default ConfigAdapter;
