import { forwardRef } from 'react';
import { Group, Text } from '@mantine/core';

const SelectApproverItem = forwardRef(
  ({ name, email, department, ...others }, ref) => (
    <div ref={ref} {...others}>
      <Group noWrap>
        <div>
          <Text size="sm">{name}</Text>
          <Text size="xs" color="dimmed">
            {email}
          </Text>
          <Text size="xs" color="dimmed">
            {department}
          </Text>
        </div>
      </Group>
    </div>
  ),
);

export default SelectApproverItem;
