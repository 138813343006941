import { useCallback, useEffect, useState } from 'react';
import { Button, Card, Divider, Group, Modal, Title } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconPlus } from '@tabler/icons-react';
import { toast } from 'react-toastify';

import useFetchVendors from '../hooks/useFetchVendors';
import UserAdapter from '../adapters/userAdapter';
import VendorAdapter from '../adapters/vendorAdapter';
import VendorsList from '../components/VendorsList';
import VendorForm from '../components/VendorForm';

function Vendors() {
  const [vendors = [], error, isLoading, invalidateVendors, refetchVendors] =
    useFetchVendors();
  const [localVendors, setLocalVendors] = useState(vendors);
  const [modalOpened, { open: openModal, close: closeModal }] =
    useDisclosure(false);

  useEffect(() => {
    if (vendors?.length > 0) {
      let mergedVendors = [...vendors];
      let preferredVendorTaxIds = [];

      try {
        // Pull vendor preferences from the localStorage.
        preferredVendorTaxIds = JSON.parse(
          localStorage.getItem('PreferredVendorTaxIds'),
        );

        // Ensure we got an array from the localStorage.
        if (!Array.isArray(preferredVendorTaxIds)) {
          preferredVendorTaxIds = [];
        }

        // Matches each vendor by vendor's tax id
        // and sets the `preferred` flag.
        mergedVendors = vendors.map((vendor) => ({
          ...vendor,
          preferred: preferredVendorTaxIds.includes(vendor.taxId),
        }));
      } catch (error) {
        console.error('Error merging preferred vendors: ', error);
      }

      setLocalVendors(mergedVendors);
    }
  }, [vendors]);

  const handleVendorSelection = useCallback(
    async ({ vendorId, taxId, preferred }) => {
      const originalLocalVendors = [...localVendors];

      // Optimistic UI update to show checkbox as checked/unchecked
      // immediately for better UX and avoid confusing user.
      // We anticipate that network request to update the preferred
      // vendors will be completed successfully.
      const updatedVendors = localVendors.map((vendor) => {
        if (vendor._id === vendorId) {
          return { ...vendor, preferred: preferred };
        }
        return vendor;
      });
      setLocalVendors(updatedVendors);

      try {
        // Permanently store the user's preference for the vendor
        const response = await UserAdapter.updatePreferredVendor({
          action: preferred ? 'add' : 'remove',
          vendorTaxId: taxId,
        });

        // Update the localStorage with the latest changes
        localStorage.setItem(
          'PreferredVendorTaxIds',
          JSON.stringify(response?.preferredVendorTaxIds || []),
        );
      } catch (error) {
        console.error(
          `Error in updating vendor preference for ${taxId}: `,
          error.message,
        );

        // Roll back the change on UI.
        setLocalVendors(originalLocalVendors);
      }
    },
    [localVendors],
  );

  const handleVendorEdit = useCallback(
    async ({ value, columnId, data }) => {
      const updatedVendorDetails = {
        ...data,
        [columnId]: value,
      };

      const updatedVendors = localVendors.map((vendor) => {
        if (vendor._id === data._id) {
          return { ...updatedVendorDetails };
        }
        return vendor;
      });
      setLocalVendors(updatedVendors);

      try {
        await VendorAdapter.setVendor(updatedVendorDetails);
      } catch (err) {
        console.error(
          '🚩 ~ Failed to update vendor details ~ err:',
          err.message,
        );
        toast.error('Failed to update vendor details.');
      } finally {
        refetchVendors();
      }
    },
    [localVendors],
  );

  const handleFormSuccess = useCallback(() => {
    toast.success('Vendor details saved successfully.');
    refetchVendors();
    closeModal();
  }, [closeModal]);

  const handleFormError = useCallback(() => {
    toast.error('Something went wrong. Please try again.');
  }, []);

  return (
    <>
      <Card>
        <Group position="apart">
          <Title order={3}>Vendor Management</Title>

          {/* Add New Vendor */}
          <Button
            type="button"
            onClick={openModal}
            leftIcon={<IconPlus size={14}></IconPlus>}>
            ADD VENDOR
          </Button>
        </Group>
        <Divider my="sm" />

        {/* Vendor's List */}
        <VendorsList
          vendors={localVendors}
          error={error}
          loading={isLoading}
          onEdit={handleVendorEdit}
          onVendorSelected={handleVendorSelection}
        />
      </Card>

      {/* Add / Edit Vendor Modal */}
      <Modal
        size="md"
        opened={modalOpened}
        onClose={closeModal}
        title="New Vendor"
        closeOnClickOutside={false}
        centered>
        <VendorForm
          onSuccess={handleFormSuccess}
          onError={handleFormError}></VendorForm>
      </Modal>
    </>
  );
}

export default Vendors;
