import httpClient from '../utils/httpClient';
import GLOBAL from '../config/global';
import { useQuery } from '@tanstack/react-query';

function useSearchDocument(searchQuery) {
  const { data, error, isLoading } = useQuery({
    queryKey: ['searchDocument', searchQuery],

    queryFn: () => searchDocuments(searchQuery),

    // Only run the query if poNumber is truthy
    enabled: !!searchQuery,

    staleTime: 60 * 1000,
  });

  const searchResult = !searchQuery || !Array.isArray(data) ? [] : data;

  return [searchResult, error, isLoading];
}

async function searchDocuments(searchQuery) {
  const response = await httpClient.get(
    `${GLOBAL.API_URL}/search?q=${searchQuery}`,
  );
  return response.data.message;
}

export default useSearchDocument;
