/* eslint-disable consistent-return */
import httpClient from '../utils/httpClient';
import GLOBAL from '../config/global';

const getTransactions = async ({ status }) => {
  const body = {
    status,
  };
  const rtnObj = await httpClient.post(`${GLOBAL.API_URL}/getDocuments`, body);
  return rtnObj.data.message;
};

const getPOTransaction = async ({ accessToken, invoiceNumber }) => {
  const body = {
    invoiceNumber,
  };
  const reqConfig = {};
  reqConfig.headers = {
    Authorization: `Bearer ${accessToken}`,
  };
  const rtnObj = await httpClient.get(
    `${GLOBAL.API_URL}/getDocumentFromInvoiceNumber/${invoiceNumber}`,
    body,
    reqConfig,
  );
  return rtnObj.data.message;
};

// const getTransactionTemplates = async () => {
//   const rtnObj = await httpClient.get(
//     `${GLOBAL.API_URL}/getTransactionTemplates`,
//   );
//   return rtnObj.data.message;
// };

// const processTransactions = async (data) => {
//   const body = {
//     transactionId: data.transactionId,
//     statusId: data.statusId,
//   };
//   const rtnObj = await httpClient.post(
//     `${GLOBAL.API_URL}/updateTransactionStatus`,
//     body,
//   );
//   return rtnObj.data.message;
// };

const failTransactions = async (data) => {
  const body = {
    transactionId: data.transactionId,
    statusId: data.statusId,
  };
  const rtnObj = await httpClient.post(
    `${GLOBAL.API_URL}/pushTransactionToFailure`,
    body,
  );
  return rtnObj.data.message;
};

const getTransactionViews = async (companyId) => {
  const body = {
    companyId: companyId,
  };
  const rtnObj = await httpClient.post(
    `${GLOBAL.API_URL}/getTransactionViews`,
    body,
  );
  return rtnObj.data.message;
};

const updateTransaction = async (data) => {
  const body = {
    transactionId: data.transactionId,
    templateObject: data.templateObject,
  };
  const rtnObj = await httpClient.post(
    `${GLOBAL.API_URL}/updateTransaction`,
    body,
  );
  return rtnObj.data.message;
};

// const createBotExpenses = async (data) => {
//   const body = {
//     transactionId: data.transactionId,
//   };
//   const rtnObj = await httpClient.post(
//     `${GLOBAL.API_URL}/bidvestFMcreateBotExpenses`,
//     body,
//   );
//   return rtnObj.data.message;
// };

// const processManualTransaction = async (data) => {
//   const body = {
//     transactionId: data.transactionId,
//     userId: '',
//   };
//   const rtnObj = await httpClient.post(
//     `${GLOBAL.API_URL}/processTransaction`,
//     body,
//   );
//   return rtnObj.data.message;
// };

const pushToSapQ = async (invoiceIds) => {
  const body = {
    invoiceIds: invoiceIds,
  };
  const rtnObj = await httpClient.post(`${GLOBAL.API_URL}/pushToSapQ`, body);
  return rtnObj.data.message;
};

const Api = {
  // getTransactionTemplates,
  getTransactions,
  getPOTransaction,
  // processTransactions,
  failTransactions,
  getTransactionViews,
  updateTransaction,
  // createBotExpenses,
  // processManualTransaction,
  pushToSapQ,
};

export default Api;
