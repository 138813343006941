import { useCallback, useEffect, useState } from 'react';
import { Title, Card, Divider, Group, Button, Modal } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconPlus } from '@tabler/icons-react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import InvoiceApproverForm from '../components/InvoiceApproverForm';
import UserList from '../components/UserList';
import useFetchUsers from '../hooks/useFetchUsers';
import UserForm from '../components/UserForm';

function Users() {
  const [users = [], error, isLoading, invalidateUsersCache, refetchUsers] =
    useFetchUsers();
  const [localUsers, setLocalUsers] = useState(users);
  const [userToEdit, setUserToEdit] = useState(null);
  const [modalOpened, { open: openModal, close: closeModal }] =
    useDisclosure(false);

  useEffect(() => {
    if (users?.length > 0) {
      setLocalUsers(users);
    }
  }, [users]);

  const handleFormSuccess = useCallback(() => {
    toast.success('User details saved successfully.');
    refetchUsers();
    setUserToEdit(null);
    closeModal();
  }, [closeModal]);

  const handleFormError = useCallback(() => {
    toast.error('Something went wrong. Please try again.');
  }, []);

  const handleEditUser = (user) => {
    setUserToEdit(user);
    openModal();
  };

  return (
    <>
      <Card>
        <Group position="apart">
          <Title order={3}>User Management</Title>

          {/* Add or Update User */}
          <Button
            type="button"
            onClick={openModal}
            leftIcon={<IconPlus size={14}></IconPlus>}>
            ADD USER
          </Button>
        </Group>
        <Divider my="sm" />

        {/* Platform Users' List */}
        <UserList
          users={localUsers}
          error={error}
          loading={isLoading}
          onEdit={handleEditUser}
        />
      </Card>

      {/* Add / Edit Invoice Approver Modal */}
      <Modal
        size="md"
        opened={modalOpened}
        onClose={() => {
          setUserToEdit(null);
          closeModal();
        }}
        title={userToEdit ? 'Edit User' : 'New User'}
        closeOnClickOutside={false}
        centered>
        <UserForm
          prefillData={userToEdit}
          onSuccess={handleFormSuccess}
          onError={handleFormError}></UserForm>
      </Modal>
    </>
  );
}

export default Users;
